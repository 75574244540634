import { message } from 'antd';
import AxiosConfig from "./AxiosConfig";
import systemUtils from "../utils/SystemUtils";
import Loading from '../pages/components/Loading/index';
import {RespData, Post, UploadFile} from './index.d';

const post:Post = async (url, param?:any, isLoading:boolean = true) => {
    const token = localStorage.getItem('ms_platform_token') || "";
    isLoading && Loading.show();

    let options = {
        REQUEST: {
            REQUEST_DATA: {
                transactionUuid: 'b5ece8d9e5e44bc6bf9e3e49b534a1cb',
                data: Object.assign(param,{token})
            },
            DEVICE_ATTRS: {
                deviceIp: "00:00:00:00",
                deviceType: systemUtils.getCurrentBrowser(),
                deviceMac: "00-00-00-00-00-00",
                deviceGps: "00.00,00.00",
                deviceVersion: "0.0.1",
                deviceOperatingSystem: systemUtils.getSystemOS(),
                appVersion: "0.0.1"
            }
        }
    };
    return new Promise( (resolve,reject) => {
        AxiosConfig.post<RespData>(url,JSON.stringify(options)).then(response =>{
            if (response.data.RESPONSE.RETURN_CODE === 'S0A00000') {
                resolve(response.data.RESPONSE.RETURN_DATA.data);
            }else if(response.data.RESPONSE.RETURN_CODE === 'EF0A0009'){
                localStorage.removeItem('ms_platform_token');
                window.location.href = '/login';
            }else {
                message.warning(response.data.RESPONSE.RETURN_DESC,2);
            }
        }).catch(err =>{
            message.error(err, 2);
        }).finally(()=>{
            isLoading && Loading.close();
        })
    })
}

const uploadFile:UploadFile = async (url, file?:any, fileTypeId?: string, loanId?: string, isLoading:boolean = true) => {
    isLoading && Loading.show();

    let postData = new FormData();
    postData.append('file',file);
    postData.append('token',localStorage.getItem('ms_platform_token') || "")
    postData.append('fileTypeId',fileTypeId+'');
    postData.append('loanId',loanId+'');

    return new Promise( (resolve,reject) => {
        AxiosConfig.post<RespData>(url,postData,{headers:{'Content-Type':'multipart/form-data;boundary=' + new Date().getTime()}}).then(response =>{
            if (response.data.RESPONSE.RETURN_CODE === 'S0A00000') {
                resolve(response.data.RESPONSE.RETURN_DATA.data);
            }else if(response.data.RESPONSE.RETURN_CODE === 'EF0A0009'){
                localStorage.removeItem('ms_platform_token');
                window.location.href = '/login';
            }else {
                message.warning(response.data.RESPONSE.RETURN_DESC,2);
            }
        }).catch(err =>{
            message.error(err, 2);
        }).finally(()=>{
            isLoading && Loading.close();
        })
    })
}

const exportedModule = {
    post, uploadFile
};

export default exportedModule
