import service from './index';

const userApi = {
    /** 获取用户信息 */
    getUserInfo:<T>() => service.post<T>('/osp/customMgr/getCustomInfo',{}, false),

    /** 更新用户信息 */
    updateUserInfo:<T>(param:any) => service.post<T>('/osp/customMgr/updateCustomInfo',param, true),

    /** 更新用户密码 */
    updateCustomPassword:<T>(param:any) => service.post<T>('/osp/customMgr/updateCustomPassword',param, true)
}

export default userApi;
