import { runInAction, makeAutoObservable } from "mobx";
import userApi from '../service/userApi'
import { UserInfo } from './index.d';

const useStore = makeAutoObservable(
    {
        userInfo:{fullName:'',mobile:'',email:'',age:'',birthDate:'',cardType:'',cardNo:'',faceBook:'',position:'',companyAddress:'',homeAddress:'',createTime:''} as UserInfo,

        getUserInfo :async function(){
            const res = await userApi.getUserInfo<UserInfo>();
            runInAction(()=>{
                this.userInfo = {...res}
            })
        }
    }
)
export default useStore;