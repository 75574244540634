const hk = {
    /** Http 返回翻译 */
    http:{
        400: '錯誤請求',
        401: '未授權，請重新登錄',
        403: '拒絕訪問',
        404: '請求錯誤,未找到該資源',
        405: '請求方法未允許',
        408: '請求超時',
        500: '服務器端出錯',
        501: '網絡未實現',
        502: '網絡錯誤',
        503: '服務不可用',
        504: '網絡超時',
        505: 'http版本不支持該請求',
        defaultTips: '網絡繁忙，請稍後再試!'
    },

    /**菜单*/
    menu:{
        home:'主页',
        aboutUs: '关于我们',
        news: '新闻',
        fundRaising: '募集资金',
        centers: '个人中心',
        login:'登录/注册',
    },

    /**通用翻译*/
    web:{
        title:'Gel',
        companyLabel: '公司',
        companyDesc: 'G.E.L 成立于新加坡，服务中小企业贸易融资和贷款的平台市场。 我们将企业与金融机构联系起来，从而更快地融资。',
        ok:'确 认',
        cancel:'取 消',
        submit: '提 交',
        yes: '是',
        no: '否',
        email:'邮箱',
        password: '密码',
        confirmPassword: '确认密码',
        add: '添加',
        operate: '操作',
        update: '修改',
        delete: '删除',
        mobile: '手机号',
        toHomepage: '返回首頁',
        updateUserInfo: '修改個人信息',
        updatePassword: '修改密码',
        verificationCode: '验证码',
        send: '发送验证码',
        afreshSend: '重新发送',
        signOut: '退出',
        login:'登录',
        register: '注册',
        registerTitle:'注 册',
        userName: '姓名',
        termsOfService: '服务条款',
        privacyPolicy: '隐私政策',
        alreadyAccount: '已有账号？立即登录',
        solutions: '解决方案',
        sme: '中小企业',
        financialInstitutions: '金融机构',
        consultants: '顾问',
        agents:'中介机构',
        contactUs:'联系我们',
        platformPolicy: '平台策略',
        contact: '联络',
        partners: '合作伙伴',
        learnMore: '了解详情',
        advantages: '优势',
        applicationsProcess: '智能贷款申请流程',
        notData: '暂无数据...',
        companyNews: '公司新闻',
        loginDesc: '登录即表明您同意 ',
        iAgree: '我同意Gel的 ',
        dashboard: '首页',
        accountSettings: '账号设置',
        age: '年龄',
        birthDate: '出生年月',
        cardType: '证件类型',
        cardNo: '证件号码',
        position: '职位',
        companyAddress: '公司地址',
        homeAddress: '家庭地址',
        joinTime: '加入时间',
        newLoan: '申请',
        registerTime: '注册日期',
        needHelp: '客服电话',
        myApplications: '贷款总数',
        lastLoginTime: '上次登录时间',
        applicationList: '融资列表',
        remarks: '备注',
        funder: '融资机构',
        applyDate: '申请日期',
        status: '状态',
        applyPeriod: '贷款期数',
        appliedAmount: '贷款金额',
        productType: '产品类型',
        companyName: '公司名称',
        detail: '明细',
        invalid: '作废',
        modifyInformation: '修改',
        uploadDocs: '上传附件',
        goHome: '返回首页',
        contactName: '联系人',
        contactPhone: '联系电话',
        financingPurposes: '融资目的',
        industry: '行业',
        amount: '金额',
        mainBusiness: '主营业务',
        turnover: '营业额',
        past6Months: '过去6个月',
        approvalAmount: '获批金额',
        approvalPeriod: '审批期数',
        repaymentMethod: '还款方式',
        repaymentDate: '还款日',
        applyYearRate: '申请年利率',
        approvalYearRate: '获批年利率',
        approvalDate: '审批日期',
        totalInterest: '总利息',
        totalAmount: '总金额',
        isSettle: '是否结清',
        isOverdue: '是否逾期',
        approvalDesc: '审批意见',
        settle: '已结清',
        notSettled: '未结清',
        overdue: '已逾期',
        notOverdue: '未逾期',
        noQuota: '暂无额度',
        perMonth: '每月',
        period: '期',
        basicsInfo: '基本信息',
        basicsDesc: '您需要多少金额? 使用周期是多久? 为了谁来贷款?',
        minimum: '最低 ',
        financingPurposesTips: '选择一个或多个融资目的',
        contactDetailsTitle: '您的联系方式（只读）',
        contactDetailsTitleDescOne: '您的联系方式是最新的吗？如果不是',
        contactDetailsTitleDescTwo: '请在申请前在此处更新。',
        organisationTitle: '您的企业信息',
        organisationDesc: '填写详细的企业信息',
        intentionalLoanPeriod: '意向贷款期数',
        productApplied: '还款方式',
        turnoverLatestMonth: '过去6个月营业额',
        intentionFunder: '意向融资机构',
        fileName: '文件名称',
        fileDesc: '文件描述',
        isMustFill: '是否必填',
        isUpload: '是否已经上传',
        fileSuffix: '文件后缀',
        url:'链接',
        commaSymbol:'、',
        periodSymbol:'。',
        maximumFile:'最大上传文件(50MB)',
    },

    /** 主页 */
    home: {
        title: '一站式解决方案',
        solutionDescOne: '轻松满足您的业务需求',
        solutionDescTwo: '简化程序',
        solutionDescThree: '无缝沟通',
        solutionDescFour: '共享文件准备工作',
        solutionDescFive: '值得信赖的合作伙伴',
        applyImmediately: '立即申请',
        applyLinkHeader: '请点击 ',
        applyLink: '此处',
        applyLinkFooter: ' 申请 G.E.L. 专家与您联系进行前期讨论。',

        chooseUs: '为什么选择我们？',
        chooseDescOne: 'G.E.L. 是一个在线商业金融门户网站。 我们与客户密切合作，以了解他们的业务生命周期和相关的业务需求。',
        chooseDescTwo: '凭借我们丰富的经验和跨行业网络，我们能够将我们的客户与合适的金融机构、合作伙伴和机会联系起来，帮助推动他们的业务取得更大的成功和更高的水平。 我们协助客户获得股权投资、营运资金、市场准入和机会等服务。',

        /**常见问题*/
        commonProblemTitle: 'FAQ 常见问题',
        /**订阅*/
        subscribe:{
            title: '启动咨询',
            subscribeNow: '提交',
            desc: '向我们提交详细信息，即表示您同意遵守我们的'
        }
    },

    /** 关于我们*/
    aboutUs:{
        title: '关于我们',
        descRowOne: '在过去的十年中，我们开展了多项成功的业务。这些公司中的每一个都有非常卑微的开始。',
        descRowTwo: '与任何企业主交谈，他们会告诉您启动和运营公司需要多少工作。必须考虑获得战略优势、发展运营能力、筹集资金、扩大客户群和驾驭竞争格局的各个方面。凭借所有这些丰富的经验，我们现在对经营成功企业的需求和要求有了更好的了解。',
        descRowThree :'多年来，我们与朋友和同事分享了我们的成功故事。我们一直在协助推动他们的商业旅途。很是高兴能成为他们自己成功的商业旅程的一部分。',
        descRowFour: '我们受到所有成功故事的启发，并希望接触更多企业。这就是启发了成立 G.E.L.的设想。 G.E.L.，代表让各个企业链接起来，将成为我们希望将企业连接起来的平台。G.E.L. 渴望与中小型企业以及新企业合作，并渴望帮助他们达到新的、更高的高度。',
        vision: '愿景',
        visionDesc: '让企业链接起来.',
        mission: '使命',
        missionDesc: 'G.E.L是 Harthney Capital Pte. Ltd.  的品牌。 这是一家金融中介机构，其使命是以最有效和最高效的方式连接企业，以满足其业务需求并充分发挥其增长潜力。',
    },

    /**募集资金*/
    fundRaising:{
        title: '中小企业综合端对端融资平台',
        descRowOne: '为中小企业提供无缝对接的贷款申请流程。实现快速提交申请进度跟踪。GEL可以帮助中小企业获得实现增长计划所需要的融资配套。',
        descRowTwo: '不需要花多余的精力和时间在各种融资方案中寻找适合于企业自身的方案 - GEL为您智能挑选最符合企业需求的方案。'
    },

    /**后台返回*/
    backstage:{
        //系统相关
        EF0A0009: '系統登錄失效，請重新登錄!',
        EF0A0010: '帳號密碼不正常，請重新輸入!',
        EF0A0011: '賬號被禁用，請聯系管理員處理!',
        EF0A0016: '無效的請求，權限不足!',
        EF0D0001: '記錄不存在!',
        EF0Z9999: '系統繁忙，請稍後再試',

        //邮件相关
        EF0E0000: '郵件發送失敗!',
        EF0E0001: '系統尚未配置郵箱服務,請登錄後臺配置!',
        EF0E0002: '尚未啟動郵箱發送功能!',
        EF0E0003: '更新郵箱配置信息失敗,請稍後再試!',

        //股票相关
        EF0F0000: '解析股票報文失敗,請查驗!',
        EF0F0001: '添加股票代碼失敗,請稍後再試!',
        EF0F0002: '更新股票代碼失敗,請稍後再試!',
        EF0F0003: '該客戶已經持有該股,請直接修改持倉等信息!',

        //客户相关
        EF0G0000: '添加客戶信息失敗,請聯系開發維護人員!',
        EF0G0001: '更新客戶信息失敗,請聯系開發維護人員!',
        EF0G0002: '該客戶信息不存在!',
        EF0G0003: '刪除用戶的股票記錄失敗,請稍後再試!',
        EF0G0004: '刪除用戶信息失敗,請稍後再試!',
        EF0G0005: '更新密碼失敗,請稍後再試!',
        EF0G0006: '無效的請求,請稍後再試!',
        EF0G0007: '添加客戶持倉失敗,請稍後再試!',
        EF0G0008: '修改客戶持倉失敗,請稍後再試!',
        EF0G0009: '刪除客戶持倉失敗,請稍後再試!',
        EF0G0010: '原密碼不正確,請稍後再試!',
        EF0G0011: '更新客戶信息失敗,該郵箱已被使用!'
    }
}
export default hk;
