import React from 'react';
import {Redirect} from 'react-router-dom'

const router = [
    {
        path: '/',
        exact: true,
        render:() => <Redirect to="/home"/>
    }, {
        path: '/customer',
        component:React.lazy(() => import('../pages/components/CustomerLayout/index')),
        router:[{
            path: '/customer',
            exact:true,
            render:()=><Redirect to="/customer/dashboard"/>,
            meta: { title: 'Dashboard', zhTitle: '首页' }
        },{
            path: '/customer/dashboard',
            component:React.lazy(() => import('../pages/customer/dashboard/index')),
            meta: { title: 'Dashboard', zhTitle: '首页' }
        },{
            path: '/customer/updateUserInfo',
            component: React.lazy(() => import('../pages/customer/updateUserInfo/index')),
            meta: { title: 'Account Settings', zhTitle: '账号设置' }
        },{
            path: '/customer/updatePassword',
            component: React.lazy(() => import('../pages/customer/updatePassword/index')),
            meta: { title: 'Change Password', zhTitle: '修改密码' }
        },{
            path: '/customer/applyStepOne',
            component: React.lazy(() => import('../pages/customer/applyStepOne/index')),
            meta: { title: 'New Financing Application', zhTitle: '新的融资申请' }
        },{
            path: '/customer/applyStepTwo/:loanId',
            component: React.lazy(() => import('../pages/customer/applyStepTwo/index')),
            meta: { title: 'Upload Document', zhTitle: '上传附件' }
        },{
            path: '/customer/loanDetail/:loanId',
            component: React.lazy(() => import('../pages/customer/loanDetail/index')),
            meta: { title: 'Loan Details', zhTitle: '贷款明细' }
        }]
    }, {
        path: '/home',
        component:React.lazy(() => import('../pages/components/HomeLayout/index')),
        router: [{
            path: '/home',
            exact: true,
            render: () => <Redirect to='/home/index'/>
        },{
            path: '/home/index',
            component: React.lazy(() => import('../pages/home/index/index'))
        },{
            path: '/home/about',
            component: React.lazy(() => import('../pages/home/about/index'))
        },{
            path: '/home/fundRaising',
            component: React.lazy(() => import('../pages/home/fundRaising/index'))
        },{
            path: '/home/news',
            component: React.lazy(() => import('../pages/home/news/index'))
        },{
            path: '/home/newDetail',
            component: React.lazy(() => import('../pages/home/newDetail/index'))
        }]
    }, {
        path: '/privacyPolicy',
        component: React.lazy(() => import('../pages/protocol/privacy/index'))
    }, {
        path: '/tc',
        component: React.lazy(() => import('../pages/protocol/tc/index'))
    }, {
        path: '/login',
        component: React.lazy(() => import('../pages/login/index'))
    }, {
        path: '/register',
        component: React.lazy(() => import('../pages/register/index'))
    }
]
export default router;
