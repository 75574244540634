const us = {
    /** Http 返回翻译 */
    http:{
        400: 'Bad request',
        401: 'Unauthorized, please log in again',
        403: 'access denied',
        404: 'Request error, the resource was not found',
        405: 'Request method not allowed',
        408: 'Request timed out',
        500: 'Server-side error',
        501: 'Network not implemented',
        502: 'Network Error',
        503: 'service is not available',
        504: 'network timeout',
        505: 'http version does not support the request',
        defaultTips: 'Network is busy, please try again later!'
    },

    /**菜单*/
    menu:{
        home: 'Home',
        aboutUs: 'About Us',
        news: 'News',
        fundRaising: 'Fund Raising',
        centers: 'Dashboard',
        login:'Login',
    },

    /**通用翻译*/
    web:{
        title:'Gel',
        companyLabel: 'Company',
        companyDesc: 'Established in Singapore, G.E.L provides a trade and loan financing platform for SMEs. We connect businesses with financial institutions to assist them in their financing needs.',
        ok:'Confirm',
        cancel:'Cancel',
        submit: 'Submit',
        yes: 'Yes',
        no: 'No',
        email:'EMail',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        add: 'add',
        operate: 'Operate',
        update: 'Update',
        delete: 'Delete',
        mobile: 'Mobile',
        toHomepage: 'Back to homepage',
        updateUserInfo: 'edit personal',
        updatePassword: 'Change Password',
        verificationCode: 'Verification Code',
        send: 'Send',
        afreshSend: 'Resend',
        signOut: 'Sign Out',
        login:'sign in',
        register: 'sign up',
        registerTitle:'Registration',
        userName: 'User Name',
        termsOfService: 'Terms of Service',
        privacyPolicy: 'Privacy Policy',
        alreadyAccount: 'Already have an account? log in immediately',
        solutions: 'Solutions',
        sme: 'SME',
        financialInstitutions: 'Financial Institutions',
        consultants: 'Consultants',
        agents:'Agents',
        contactUs:'Contact Us',
        platformPolicy: 'Platform Policy',
        contact: 'Contact',
        partners: 'Partners',
        learnMore: 'Learn More',
        advantages: 'Advantages',
        applicationsProcess: 'Smart Loan Application Process',
        notData: 'No data...',
        companyNews: 'Company News',
        loginDesc: 'By logging in you agree ',
        iAgree: 'I agree ',
        dashboard: 'Dashboard',
        accountSettings: 'Account Settings',
        age: 'Age',
        birthDate: 'Date of birth',
        cardType: 'Type of certificate',
        cardNo: 'ID number',
        position: 'Position',
        companyAddress: 'Office address',
        homeAddress: 'Family address',
        joinTime: 'Join time',
        newLoan: 'New',
        registerTime: 'Date Joined',
        needHelp: 'Need Help?',
        myApplications: 'Financing Applications',
        lastLoginTime: 'Last Login',
        applicationList: 'Financing Applications',
        remarks: 'Remarks',
        funder: 'Financing Institution',
        applyDate: 'Application Date',
        status: 'Status',
        applyPeriod: 'Loan Period',
        appliedAmount: 'Applied Amount',
        productType: 'Product Type',
        companyName: 'Company Name',
        detail: 'Detail',
        invalid: 'Invalid',
        modifyInformation: 'Modify Information',
        uploadDocs: 'Upload File',
        goHome: 'Go Home',
        contactName: 'Contact Name',
        contactPhone: 'Contact Phone',
        financingPurposes: 'Financing Purposes',
        industry: 'Industry',
        amount: 'Amount',
        mainBusiness: 'Main Business',
        turnover: 'Turnover',
        past6Months: 'Past 6 months',
        approvalAmount: 'Approval Amount',
        approvalPeriod: 'Approval Period',
        repaymentMethod: 'Repayment Method',
        repaymentDate: 'Repayment Date',
        applyYearRate: 'Apply Year Rate',
        approvalYearRate: 'Approval Year Rate',
        approvalDate: 'Approval Date',
        totalInterest: 'Total Interest',
        totalAmount: 'Total Amount',
        isSettle: 'Fully Settled',
        isOverdue: 'No Quota',
        approvalDesc: 'Approval Comments',
        settle: 'Settle',
        notSettled: 'Not Settled',
        overdue: 'Overdue',
        notOverdue: 'Not Overdue',
        noQuota: 'No Quota',
        perMonth: 'Per Month',
        period: 'Period',
        basicsInfo: 'The Basics',
        basicsDesc: 'How much do you need? How long? For Whom?',
        minimum: 'Minimum ',
        financingPurposesTips: 'Select 1 or more financing purposes',
        contactDetailsTitle: 'You Contact Details(Read Only)',
        contactDetailsTitleDescOne: 'Is your Contact Details up to date? if not ',
        contactDetailsTitleDescTwo: 'Please update it here before applying.',
        organisationTitle: 'Your Organisation Detail',
        organisationDesc: 'Tell us more your organisation',
        intentionalLoanPeriod: 'Intentional loan period',
        productApplied: 'Product Applied',
        turnoverLatestMonth: 'Turnover in the past 6 months',
        intentionFunder: 'Choice of Financial Institutions',
        fileName: 'File Name',
        fileDesc: 'File Description',
        isMustFill: 'Required',
        isUpload: 'Uploaded',
        fileSuffix: 'File Suffix',
        url:'URL',
        commaSymbol:' and ',
        periodSymbol:'.',
        maximumFile:'Maximum(50MB)',
    },

    /** 主页 */
    home: {
        title: 'One Stop Solution',
        solutionDescOne: 'Easy Way to Meet Your Business Needs',
        solutionDescTwo: 'Simplified Procedures',
        solutionDescThree: 'Seamless Communication',
        solutionDescFour: 'Collaborate Document Preparation Effort',
        solutionDescFive: 'Trustworthy Partners',
        applyImmediately: 'Apply Now',
        applyLinkHeader: 'Please click ',
        applyLink: 'here',
        applyLinkFooter: ' to request for a G.E.L. specialist to contact you for a no-obligation discussion.',

        chooseUs: 'Why Choose Us?',
        chooseDescOne: 'G.E.L. is an online business financial portal. We collaborate closely with our clients to understand their business life cycle and their associated business needs.',
        chooseDescTwo: 'With our extensive experience and network across industries, we are able connect our clients to the right financial institutions, partners, and opportunities to help propel their businesses to greater success and heights. We assist clients in obtaining equity investment, working capital, market access, and opportunities, among other services.',

        /**常见问题*/
        commonProblemTitle: 'FAQ Common Problem',
        /**订阅*/
        subscribe:{
            title: 'Ask',
            subscribeNow: 'Submit',
            desc: 'By submitting your information to us, you agree to comply by our ',
        }
    },

    /** 关于我们*/
    aboutUs:{
        title: 'About Us',
        descRowOne: 'In the last decade, we started multiple successful businesses. Each one of these companies had very humble beginnings.',
        descRowTwo: 'Speak to any business owner, and they will tell you how much work it will take to get the company up and running. Every aspect from gaining a strategic advantage, developing operational capabilities, raising funds, expanding the customer base, and navigating the competitive landscape must be considered. With all of these vast experiences, we now have a much better understanding of the needs and requirements of running a successful business.',
        descRowThree :'Over the years, we’ve shared our success stories with our friends and associates. We’ve have been assisting and nudging them along the way in their business ventures. It is always such a joy to be part of their own successful business journey.',
        descRowFour: 'We are inspired by all of the success stories and want to reach out to more businesses. And this is how G.E.L. is conceived. G.E.L., which stands for Getting Entities Linked, will be a platform that we hope will connect entities. G.E.L. aspires to collaborate with small and medium-sized businesses, as well as new ventures, and is eager to assist them in reaching new and greater heights.',
        vision: 'Vision',
        visionDesc: 'Getting entities linked.',
        mission: 'Mission',
        missionDesc: 'G.E.L. is a brand of Harthney Capital Pte. Ltd., a financial intermediary whose mission is to connect businesses in the most effective and efficient way possible in order to meet their business needs and reach their full growth potential.',
    },

    /**募集资金*/
    fundRaising:{
        title: 'Comprehensive End-to-end financing platform for SMEs',
        descRowOne: 'Providing a streamlined loan application process for SMEs. Able to track the status of an application submission in real time. GEL can assist SMEs with sourcing for a suitable funding package in order to accomplish their expansion objectives.',
        descRowTwo: 'There is no need to waste time and resources to search for a solution that is a right fit for your company. GEL’s system will intelligently choose the one that best suits your company\'s needs.'
    },

    /**后台返回*/
    backstage:{
        //系统相关
        EF0A0009: 'System login failed, please log in again!',
        EF0A0010: 'The account password is abnormal, please re-enter!',
        EF0A0011: 'The account is disabled, please contact the administrator!',
        EF0A0016: 'Invalid request, insufficient permissions!',
        EF0D0001: 'Record does not exist!',
        EF0Z9999: 'The system is busy, please try again later',

        //邮件相关
        EF0E0000: 'Failed to send mail!',
        EF0E0001: 'The system has not configured the mailbox service, please log in to the background configuration!',
        EF0E0002: 'Email sending function has not been activated!',
        EF0E0003: 'Failed to update email configuration information, please try again later!',

        //股票相关
        EF0F0000: 'Failed to parse the stock message, please check!',
        EF0F0001: 'Failed to add stock code, please try again later!',
        EF0F0002: 'Failed to update stock code, please try again later!',
        EF0F0003: 'The client already holds the stock, please directly modify the position and other information!',

        //客户相关
        EF0G0000: 'Failed to add customer information, please contact the developer and maintainer!',
        EF0G0001: 'Failed to update customer information, please contact the developer and maintainer!',
        EF0G0002: 'The customer information does not exist!',
        EF0G0003: 'Failed to delete user stock record, please try again later!',
        EF0G0004: 'Failed to delete user information, please try again later!',
        EF0G0005: 'Password update failed, please try again later!',
        EF0G0006: 'Invalid request, please try again later!',
        EF0G0007: 'Failed to add client position, please try again later!!',
        EF0G0008: 'Failed to modify the client position, please try again later!',
        EF0G0009: 'Failed to delete client position, please try again later!',
        EF0G0010: 'The original password is incorrect, please try again later!',
        EF0G0011: 'Failed to update customer information, the mailbox has been used!'
    }
}
export default us;
