import React, {useCallback, useEffect} from 'react';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';
import { LoadingProps } from './index.d';

const GlobalLoading = React.memo((props: LoadingProps) => {

    const _hide = useCallback(() => {
        setTimeout(() => {
            props.ReactDOM.unmountComponentAtNode(props.refs);
            props.refs.parentNode?.removeChild(props.refs);
        }, 2000)

    },[props.ReactDOM,props.refs])

    useEffect(()=>{
        if(props.isAutoClose) {
            setTimeout(() => {
                _hide();
            }, 2000);
        }
    },[_hide,props.isAutoClose])

    return(
        <div className={styles.loader}>
            <div className={styles.body}>
                <div className={styles.ljSpin}>
                    <span className={styles.ljSpinDot}>
                        <i className={styles.ljSpinDotItem}></i>
                        <i className={styles.ljSpinDotItem}></i>
                        <i className={styles.ljSpinDotItem}></i>
                        <i className={styles.ljSpinDotItem}></i>
                    </span>
                </div>
                <div className={styles.text}>
                    {props.content ? props.content : 'loading...'}
                </div>
            </div>
        </div>
    )
});
/**
 * 显示蒙版
 * content: 显示文字内容
 * isAutoClose: 是否启动自动关闭
 * @param options
 */
const show = (options?:{content?:string,isAutoClose?:boolean}) => {
    if(typeof document === 'undefined'){
        return;
    }
    if (document.querySelector('.jf-dialog-cont')) {
        return;
    }

    const container = document.createElement('div');
    container.className = 'jf-dialog-cont';
    document.body.appendChild(container);

    ReactDOM.render(
        <GlobalLoading refs={container} ReactDOM={ReactDOM} content={options?.content} isAutoClose={options?.isAutoClose}/>, container
    )
}

/**
 * 关闭蒙版
 */
const close = () => {
    if(typeof document === 'undefined'){
        return;
    }
    const container = document.querySelector('.jf-dialog-cont');
    if(container){
        ReactDOM.unmountComponentAtNode(container);
        container.parentNode?.removeChild(container);
    }
}

const declareLoading = {show,close}
export default declareLoading;