import React,{Suspense} from 'react';
import {ConfigProvider} from "antd";
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import PageLoading from './pages/components/PageLoading/index';
import { Provider } from 'mobx-react';
import intl from 'react-intl-universal';
import useStore from './store/index';
import router from './router';
import 'antd/dist/antd.css';
import './index.css';

const currentLocale = localStorage.getItem('gel_language') === 'us' ? 'us': 'hk';

const locales = {
  [currentLocale]: require(`./locale/${currentLocale}`).default
};

intl.init({
  currentLocale: currentLocale,
  locales: locales
}).then(res => {})

ReactDOM.render(
  <ConfigProvider locale={locales[currentLocale]}>
    <Provider useStore={useStore}>
      <BrowserRouter>
          <Suspense fallback={<PageLoading/>}>
              {renderRoutes(router)}
          </Suspense>
      </BrowserRouter>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
