import AxiosConfig from 'axios';

AxiosConfig.defaults.baseURL = 'http://www.gelrightnow.com';
//AxiosConfig.defaults.baseURL = 'http://localhost:8090';
AxiosConfig.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
AxiosConfig.defaults.timeout = 18000;

/** 请求拦截器(请求之前的操作) */
AxiosConfig.interceptors.request.use(
    config => {
        return config;
    },
    err => {
        return Promise.reject(err);
    }
)

/** 请求之后的操作 */
AxiosConfig.interceptors.response.use(
    config => {
        return config;
    },
    err => {
        if(err && err.response) {
            switch (err.response.status) {
                case 400:
                    err.message = '错误请求';
                    break;
                case 401:
                    err.message = '未授权，请重新登录';
                    break;
                case 403:
                    err.message = '拒绝访问';
                    break;
                case 404:
                    err.message = '请求错误,未找到该资源';
                    break;
                case 405:
                    err.message = '请求方法未允许';
                    break;
                case 408:
                    err.message = '请求超时';
                    break;
                case 500:
                    err.message = '服务器端出错';
                    break;
                case 501:
                    err.message = '网络未实现';
                    break;
                case 502:
                    err.message = '网络错误';
                    break;
                case 503:
                    err.message = '服务不可用';
                    break;
                case 504:
                    err.message = '网络超时';
                    break;
                case 505:
                    err.message = 'http版本不支持该请求';
                    break;
                default:
                    err.message = `连接错误${err.response.status}`;
            }
        } else {
            err.message = "网络信号不佳，数据包丢失，请稍后再试!"
        }
        return Promise.reject(err.message);
    }
)

export default AxiosConfig;
